import React from 'react'
import Layout from '../../layouts/layout'
import ReferAFriendPage from '../../components/pages/ReferAFriendPage'

const FeaturesPageWrapper = () => {
  if (typeof window === "undefined") return null
  return <Layout>
    <ReferAFriendPage />
  </Layout>
}

export default FeaturesPageWrapper
