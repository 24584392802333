export const NON_DECIMAL_CURRENCIES = [
  'bif',
  'clp',
  'djf',
  'gnf',
  'jpy',
  'kmf',
  'krw',
  'mga',
  'pyg',
  'rwf',
  'ugx',
  'vnd',
  'vuv',
  'xaf',
  'xof',
  'xpf',
]

export const CURRENCIES = {
  'gbp': '£',
  'usd': '$',
  'nzd': 'NZ$',
  'aud': 'AU$',
  'cad': 'CA$',
  'eur': '€',
}
