import React from 'react'
import styled from 'styled-components'
import { ReactComponent as Facebook } from '../../../assets/images/icons/icon-facebook.svg'
import Button from '../Button/IconButton'

interface Props {
  height?: number,
  width?: number,
  size?: number
  color?: string,
}

export const Icon = styled.div`
  height: ${(props: Props) => props.size || props.height}px;
  width: ${(props: Props) => props.size || props.width}px;
  position: absolute;
  display: inline-block;
  font-size: 16px;
`

export default (props: Props) => <Icon {...props}>
  <Button height={props.size || props.height} width={props.size|| props.width} size='small' backgroundColor={props.color} icon={<Facebook />}>
  </Button>
</Icon>
