import Button from 'components/UI/Button'
import Input from 'components/UI/Input'
import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import useData from './useData'

const Wrapper = styled.div`
  text-align: center;
  margin-bottom: 16px;
  > .title {
    font-size: 16px;
    color: #fff;
    margin-bottom: 10px;
  }

  .success, .error {
    font-size: 16px;
    font-family: 'Libre Baskerville';
  }
  .error {
    color: red;
  }

  .success {
    color: #8CC63F;
  }

  input { margin-bottom: 10px; }
`

const InviteFriendByEmail = () => {
  const { inviteFriends } = useData()
  const [email, setEmail] = useState('')
  const [incorrectEmail, setIncorrectEmail] = useState(false)
  const [inviteSent, setInviteSent] = useState(false)

  const validEmail = useMemo(() => {
    return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
  }, [email])


  const onSubmit = async () => {
    if (!email) return
    if (!validEmail) {
      setIncorrectEmail(true)
      setTimeout(() => {
        setIncorrectEmail(false)
      }, 3000)
    } else {
      const res = await inviteFriends([email])
      if (res) {
        setInviteSent(true)
        setTimeout(() => {
          setInviteSent(false)
        }, 3000)
        setEmail('')
      }
    }
  }

  return <Wrapper>
    <div className='title'>Send an invite by email</div>
    { incorrectEmail && <div className='error'>This email is incorrect</div> }
    { inviteSent && <div className='success'>Invite has been sent!</div> }
    <Input placeholder="Friend's email address" value={email} onChange={e => setEmail(e.target.value)} />
    <Button theme='primary' size='x-small' disabled={!validEmail} onClick={onSubmit}>Send</Button>
  </Wrapper>
}

export default InviteFriendByEmail
