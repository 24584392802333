import Input from 'components/UI/Input'
import { Button } from 'nzk-react-components'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { CURRENCIES, NON_DECIMAL_CURRENCIES } from '../../../constants/currencies'
import { AccountPageState } from '../../../layouts/AccountPagesLayout/index.state'
import { useReferral } from '../../Adverts/Referral/useReferral'
import Facebook from '../../UI/Icons/Facebook'
import Link from '../../UI/Icons/Link'
import Whatsapp from '../../UI/Icons/Whatsapp'
import Loader from '../../UI/Loader'
// @ts-ignore
import PrizeImage from './assets/prize-offer.png'
// @ts-ignore
import ReferImage from './assets/UK-5-offer.png'
// @ts-ignore
import USReferImage from './assets/US-5-offer.jpg'

import InviteFriendByEmail from './components/InviteFriendByEmail'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Rammetto One', cursive;
  padding-bottom: 20px;
`

const Content = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Title = styled.div`
  font-size: 30px;
  color: #ffffff;
  margin: 24px 0;
  text-shadow: 0px 4px #afafaf, 1px 12px #000000;
`

const SubTitle = styled.h1`
  color: #ffffff;
  font-family: 'Rammetto One';
  margin-top: 50px;
  font-size: 16px;
`

const BoxTitle = styled.h1`
  color: ${props => props.color ? props.color : '#FFFFFF'} !important;
  font-family: 'Rammetto One';
  font-size: 14px;
  margin-left: 10px;
`

const Box = styled.div<{ backgroundColor?: string }>`
  width: 100%;
  padding: 25px 20px;
  border-radius: 20px;
  background-color: ${props => props.backgroundColor ? props.backgroundColor : '#341644'};
  width: 280px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  a { 
    font-family: 'LibreBaskerville';
    color: #FFFFFF;
    font-size: 13px;
    margin-left: 10px;
  }
  img {
    margin: 0 -20px;
  }
  input {
    font-family: 'LibreBaskerville';
  }
  > .title {
    font-size: 16px;
    color: #fff;
    margin-bottom: 10px;
  }
`

const Section = styled.div`
  color: #FFFFFF!important;
  width: 100%;
  height: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 24px;
  position: relative;
  > :first-child {
    margin-top: 0;
    font-family: 'LibreBaskerville';
  }
  > :last-child {
    position: relative;
    top: 20px;
    position: absolute;
    right: 10px;
  }
`

const Empty = styled.div`
  color: #FFFFFF!important;
  width: 100%;
  height: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 24px;
  position: relative;
  > :first-child {
    margin-top: 0;
    font-family: 'LibreBaskerville';
  }
`

const PaymentSection = styled.p<{ paid: boolean }>`
  color: ${props => !props.paid ? '#afafaf' : '#FFFFFF'};
`

const Boxes = styled.div`
  @media (max-width: 1040px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  @media (min-width: 1040px) {
    display: grid;
    margin-top: 10px;
    grid-template-columns: repeat(2, 1fr);
    > div {
      margin-right: 50px;
    }
  }
`

const ReferalBox = styled(Box)`
  >${Section}:nth-child(even) {
    background-color: #FFFFFF0D;
  }
  >${Section}:nth-child(odd) {
    background-color: #FFFFFF12;
  }
`

const LinkIcon = styled.div`

  > :last-child { 
    height: 25px;
    margin-left: 10px;
  }
`

const LinkSection = styled.div `
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${LinkIcon} {
    margin-bottom: 10px;
  }
  cursor: pointer;
`

const Info = styled.div`
  color: #ffffff;
  border-radius: 20px;
  font-size: 16px;
  line-height: 30px;
  text-align: left;
  margin-left: 10px;
  max-width: 250px;
  font-family: 'LibreBaskerville';
  display: flex;
  flex-direction: columns;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
`
const statusInfo = {
  true: {
    color: '#8CC63F',
    title: 'Subscribed'
  },
  false: {
    color: '#901A26',
    title: 'Trial Only'
  },
}

const Paid = styled.div<{ status: string }>`
  border-radius: 12pt;
  background-color: ${props => statusInfo[props.status].color};
  font-size: 10px;
  width: 100px;
  text-align: center;
  left: 0;
`

const ReferAFriend = () => {
  const [loading, setLoading] = useState(true)
  const { subscription, referrals } = AccountPageState.useContainer()
  const [currency, setCurrency] = useState('$')
  const [copied, setCopied] = useState(false)
  const { init, referralLink, locale, referralCode } = useReferral()
  const [divideBy, setDivideBy] = useState(1)
  const refImage = (currency === '$' ? USReferImage : ReferImage)

  const copyLink = () => {
    try {
      navigator.clipboard.writeText(referralLink)
      setCopied(true)
      setTimeout(() => {
        setCopied(false)
      }, 3000)
    } catch (err) {
      // Failed to copy to clipboard
    }
  }

  const facebookLink = () => {
    window.open(`https://www.facebook.com/sharer.php?u=https%3A%2F%2Fwww.nightzookeeper.com%2F${locale}%2Fparents%2Fstart%2F%3Fcode%3D${referralCode}&layout=button&size=large&width=89&height=28&appId`, '_blank') 
  }

  const whatsappLink = () => {
    const text=`Have you heard of Night Zookeeper? Night Zookeeper makes learning to write easy and fun for ages 6-12. Earn us both ${currency}5 credit by signing up with my link`
    window.open(`whatsapp://send?text=${text} ${referralLink}`, '_blank')
  }

  useEffect(() => {
    init()
    if(subscription.plan) {
      setCurrency(CURRENCIES[subscription.plan.currency])
      setDivideBy(NON_DECIMAL_CURRENCIES.includes(currency) ? 1: 100)
      setLoading(false)
    }
  }, [subscription])

  return <Wrapper>
    {
    loading
    ? <Loader />
    : 
    <Content>
      <Title>Refer a Friend</Title>
      <Boxes>
        <div>
          <SubTitle>Invite Friends</SubTitle>
          <Box>
            <InviteFriendByEmail />
            <div className='title'>Your Referral Link</div>
            <Input value={referralLink} />
            <LinkSection> 
              <LinkIcon onClick={copyLink}>
                { /* eslint-disable-next-line */ }
                <Link color="#009EE2" size={25} />
                <Button backgroundColor="#009EE2" size="small">{copied ? 'Copied!' : 'Copy Link'}</Button>
              </LinkIcon>
              <LinkIcon onClick={facebookLink}>
                <Facebook color="#025899" size={25} />
                <Button backgroundColor="#025899" size="small">Share to Facebook</Button>
              </LinkIcon>
              <LinkIcon onClick={whatsappLink}>
                <Whatsapp color="#25D366" size={25} />
                <Button backgroundColor="#25D366" size="small">Share to Whatsapp</Button>
              </LinkIcon>
            </ LinkSection>
            <Info>Receive {currency}5 off your next payment for every successful referral. Your friend will receive {currency}5 too!</Info>
            <img src={refImage} alt={`${currency}5 off`} />
            <Info>You will also get a special mystery gift for referring this month! </Info>
            <img src={PrizeImage} alt='Mistery Gift' />
          </Box>
        </div>
        <div>
          <SubTitle>Referral Credit</SubTitle>
          <ReferalBox>
            <BoxTitle>Credit in the next payment</BoxTitle>
            <BoxTitle color="#8CC63F">{currency}{subscription.customer.balance < 0 ? (subscription.customer.balance * -1) / divideBy : 0}</BoxTitle>
          </ReferalBox>
          <ReferalBox>
            <BoxTitle>Credit Paid Historically</BoxTitle>
            <BoxTitle >{currency}{5 * referrals.filter(d => d?.paid).length}</BoxTitle>
            { referrals.length > 0 ? referrals.filter(r => r.user).map(r => <Section key={r.user._id} > 
              <p>{r.user.email}</p>
              <Paid status={r.paid.toString()}>
                {statusInfo[r.paid].title}
              </Paid>
              <PaymentSection paid={r.paid}>+ {currency}5</PaymentSection>
              </Section>
            ) : 
            <Empty>
              <p>You do not have any referrals</p>
            </Empty>
            }
          </ReferalBox>
        </div>
      </Boxes>
    </Content>
    }
    
  </Wrapper>
}


export default (props) =>
  <AccountPageState.Provider>
    <ReferAFriend {...props} />
  </AccountPageState.Provider>

