import { useApolloClient } from '@apollo/client'
import INVITE_FRIENDS from './inviteFriends.graphql'

const useData = () => {
  const client = useApolloClient()

  const inviteFriends = async (emails): Promise<boolean> => {
    const { data } = await client.mutate({
      mutation: INVITE_FRIENDS,
      variables: {
        emails
      }
    })
    return data.inviteFriends
  }
  return { inviteFriends }
}

export default useData
