import { useApolloClient } from '@apollo/client'
import { useState } from 'react'
import GET_DATA from './getData.graphql'

export const useReferral = () => {
  const [referralLink, setReferralLink] = useState('')
  const [currency, setCurrency] = useState('')
  const [locale, setLocale] = useState('')
  const [referralCode, setReferralCode] = useState('')

  const client = useApolloClient()

  const init = async () => {
    const { data } = await client.query({
      query: GET_DATA,
      errorPolicy: 'all',
    })
    const country = data.me.countryCode === 'US' ? 'en-us' : 'en-gb'
    const code = data.me.referralCode
    setCurrency(
      data.me.subscription &&
        data.me.subscription.plan &&
        data.me.subscription.plan.currency
    )
    setLocale(country)
    setReferralCode(code)
    setReferralLink(
      `https://www.nightzookeeper.com/${country}/parents?referralCode=${code}`
    )
  }

  return { init, referralLink, locale, currency, referralCode }
}
